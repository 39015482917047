import * as PageService from '../Services/FirebasePage.web'

export function createAndListenPage(
  workspaceId: string,
  noteId: string,
  pageNumber: number,
  callback: (page: IPage) => void,
): Function {
  return PageService.createAndListenPage(
    workspaceId,
    noteId,
    pageNumber,
    callback,
  )
}

export function save(page: IPage) {
  page.version = page.version + 1
  return PageService.save(page)
}

export async function update(id: string, values: object) {
  return PageService.update(id, values)
}
