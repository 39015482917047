import * as Actions from '../../../Modules/Actions'

const initialState: SessionState = {
  loading: true,
  initializing: true,
  user: null,
}

function SessionReducer(state: SessionState = initialState, action: any) {
  switch (action.type) {
    case Actions.SESSION_STATE_CHANGE:
      return stateChange(state, action.payload.user)

    case Actions.REQUEST_WORKSPACE_SETUP:
    case Actions.CHANGE_WORKSPACE:
      return initialized(state)
  }

  return state
}

function stateChange(state: SessionState, user: any) {
  return {
    ...state,
    loading: false,
    user,
    initializing: user === null ? false : state.initializing,
  }
}

function initialized(state: SessionState) {
  return {
    ...state,
    initializing: false,
  }
}

export default SessionReducer
