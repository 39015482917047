import * as React from 'react'
import loadable from '@loadable/component'
import {connect} from 'react-redux'
import * as WorkspaceModule from '../Modules/WorkspaceModule'
import {RouteComponentProps, withRouter} from 'react-router-dom'

const LoadableComponent = loadable(() => import('@comp/pages/SetupPage'))

function SetupPage(props: any) {
  return <LoadableComponent {...props} />
}

const mapStateToProps = ({
  ui: {
    SetupWorkspacePage: {submitting},
  },
}: any) => ({
  submitting,
})

const mapDispatchToProps = (dispatch: any, ownProps: RouteComponentProps) => {
  return {
    onSubmit: async (values: {workspaceName: string}) => {
      const {workspace, note} = await dispatch(
        WorkspaceModule.createFirstWorkspace(values.workspaceName),
      )
      ownProps.history.push(`/${workspace.id}/${note.id}`)
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SetupPage),
)
