import * as React from 'react'
import {AppInitializer, Auth} from '.'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import LoginPage from '../Containers/LoginPageContainer'
import NotePage from '../Containers/NotePageContainer'
import SetupWorkspacePage from '../Containers/SetupWorkspaceContainer'

function Routes() {
  return (
    <Router>
      <Auth>
        <AppInitializer>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/setup_workspace">
              <SetupWorkspacePage />
            </Route>
            <Route path="/:workspaceId/:noteId">
              <NotePage />
            </Route>
            <Route path="/">
              <div>あなたはログインしています！！</div>
            </Route>
          </Switch>
        </AppInitializer>
      </Auth>
    </Router>
  )
}

export default Routes
