import * as React from 'react'
import styled from 'styled-components'
import {Icon, Text} from '../atoms'
import {View, TouchableOpacity} from 'react-native'
import * as variables from '../../variables'

interface Props {
  label: string
  submenu: Array<{menu: string}>
}

function NoteIndexItem({label, submenu}: Props) {
  const [isOpen, setOpen] = React.useState(true)

  return (
    <Wrapper>
      <TouchableOpacity onPress={() => setOpen(!isOpen)}>
        <MenuItem accessibilityRole="menuitem" sub={false}>
          <Icons>
            <Arrow
              button
              name="arrowDropright"
              iconSize={13}
              buttonSize={25}
              color={variables.colors.text.greyLight}
              open={isOpen}
              margin={0}
            />
            <Icon
              name="book"
              iconSize={12}
              buttonSize={25}
              margin={0}
              color={variables.colors.text.greyDarker}
            />
          </Icons>
          <StyledText sub={false}>{label}</StyledText>
        </MenuItem>
      </TouchableOpacity>
      {isOpen &&
        submenu.map((value, i) => (
          <SubItem key={`submenu-${i}`}>{value.menu}</SubItem>
        ))}
    </Wrapper>
  )
}

function SubItem({children}: any) {
  return (
    <TouchableOpacity>
      <MenuItem sub={true}>
        <Icon
          name="bookmark"
          iconSize={12}
          buttonSize={25}
          color={variables.colors.text.greyDarker}
        />
        <StyledText sub>{children}</StyledText>
      </MenuItem>
    </TouchableOpacity>
  )
}

const Icons = styled(View)`
  flex-direction: row;
  align-items: center;
`

interface ArrowProp {
  open: boolean
}

const Arrow = styled(Icon)`
  // transform: rotate(${({open}: ArrowProp) => (open ? 90 : 0)}deg);
  i {
    transform: rotate(90deg);
    transition: transform 1s linear;  
  }
`

interface MenuItemProp {
  sub: boolean
}

const MenuItem = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 4px;
  padding-left: ${({sub}: MenuItemProp) => (sub ? 42 : 0)}px;

  &:hover {
    background-color: ${variables.colors.background.light};
  }
`

const StyledText = styled(Text)`
  font-size: 12px;
  margin: 0 10px;
  margin-left: ${({sub}: MenuItemProp) => (sub ? 0 : 10)}px;
  color: ${variables.colors.text.greyDarker};
`

const Wrapper = styled(View)``

export default NoteIndexItem
