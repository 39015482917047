import * as React from 'react'
import {connect} from 'react-redux'
import Page from '@comp/organisms/Page'
import PageEntity from '../Entities/Page'
import * as PageRepository from '../Repositories/PageRepository'
import * as PageModule from '../Modules/PageModule'

interface PageContainerProps {
  workspaceId: string
  noteId: string
  pageNumber: number
  update: (pageId: string, value: string, version: number) => void
}

function PageContainer({
  workspaceId,
  noteId,
  pageNumber,
  update,
}: PageContainerProps) {
  const [page, setPage] = React.useState<IPage | null>(null)

  const pageId = page === null ? null : page.id
  const text = page === null ? '' : page.text
  const version = page === null ? 0 : page.version

  React.useEffect(() => {
    const unsubscribe = PageRepository.createAndListenPage(
      workspaceId,
      noteId,
      pageNumber,
      (receive: IPage) => {
        if (page === null || version < receive.version) {
          setPage(receive)
        }
      },
    )

    return () => {
      unsubscribe()
    }
  }, [noteId, page, pageNumber, version, workspaceId])

  React.useEffect(() => {
    const timerID = setTimeout(() => {
      if (pageId !== null) {
        update(pageId, text, version)
      }
    }, 500)

    return () => {
      clearTimeout(timerID)
    }
  }, [pageId, text, update, version])

  return (
    <Page
      editing
      html=""
      value={page === null ? '' : page.text}
      onBeforeChange={(editor, data, value) => {
        if (page !== null) {
          setPage(
            PageEntity.of(
              workspaceId,
              noteId,
              page.id,
              '',
              value,
              page.version + 1,
              page.pageNumber,
              page.createdAt,
              page.updatedAt,
            ),
          )
        }
      }}
    />
  )
}

const mapStateToProps = (state: AppState, ownProps: any) => {
  const {note} = state.ui.NotePage

  if (note === null) {
    return {}
  }

  return {
    workspaceId: note.workspaceId,
    noteId: note.id,
    pageNumber: ownProps.index + 1,
    html: '',
    value: '',
    editing: false,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  update: (pageId: string, value: string, version: number) =>
    dispatch(PageModule.update(pageId, value, version)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageContainer)
