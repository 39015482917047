import {db, timestamp} from './Firebase.web'
import {Note} from '../Entities'

export async function getLastUpdateEntity(
  workspaceId: string,
): Promise<INote | null> {
  const notes = await db
    .collection('notes')
    .where('workspaceId', '==', workspaceId)
    .orderBy('updatedAt', 'desc')
    .limit(1)
    .get()

  if (notes.empty) {
    return null
  }

  const note = notes.docs[0]

  return Note.of(
    note.id,
    note.data().title,
    note.data().workspaceId,
    note.data().pageCount || 1,
    note.data().createdAt.toDate(),
    note.data().updatedAt.toDate(),
  )
}

export async function save(note: INote): Promise<null | Error> {
  try {
    await db
      .collection('notes')
      .doc(note.id)
      .set({
        workspaceId: note.workspaceId,
        title: note.title,
        pageCount: note.pageCount,
        createdAt: note.createdAt || timestamp(),
        updatedAt: note.updatedAt || timestamp(),
      })
  } catch (e) {
    return e
  }

  return null
}

export function listenNote(noteId: string, callback: (note: INote) => void) {
  return db
    .collection('notes')
    .doc(noteId)
    .onSnapshot(doc => {
      if (!doc.exists) {
        return
      }

      const {title, workspaceId, pageCount = 1, createdAt, updatedAt} =
        doc.data() || {}
      const n = Note.of(
        doc.id,
        title,
        workspaceId,
        pageCount,
        createdAt,
        updatedAt,
      )

      callback(n)
    })
}

export async function incrementPageNumber(noteId: string) {
  const ref = db.collection('notes').doc(noteId)
  const note = await ref.get()

  if (!note.exists) {
    console.log('note not found')
    return
  }

  const pageCount = (note.data() || {pageCount: 1}).pageCount + 1

  return ref.update({pageCount})
}
