/**
 * @format
 */

import {AppRegistry} from 'react-native'
import App from './src/App.web'
import {name as appName} from './app.json'

AppRegistry.registerComponent(appName, () => App)

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
})

if (module.hot) {
  // このモジュールが差し替え可能とする
  module.hot.accept(function(err) {
    if (err) {
      console.error(err)
    }
  })
}
