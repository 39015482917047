import * as React from 'react'
import {NoteTemplate} from '../templates'

interface NotePageProps {
  pageCount: number
  pageComponent: any
  NoteInfo: any
}

function NotePage({NoteInfo, pageCount, pageComponent}: NotePageProps) {
  return (
    <NoteTemplate
      pageCount={pageCount}
      pageComponent={pageComponent}
      NoteInfo={NoteInfo}
    />
  )
}

export default NotePage
