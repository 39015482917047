import * as React from 'react'
import {Row, Grid, Col} from 'react-native-easy-grid'
import {StyleSheet, Dimensions, View} from 'react-native'
import * as variables from '../../variables'
import {Title, Input, Button} from '../atoms'

interface SetupPageProps {
  submitting?: boolean
  onSubmit: (values: {workspaceName: string}) => void
}

const {height} = Dimensions.get('window')

function SetupPage({submitting, onSubmit}: SetupPageProps) {
  const [values, setValues] = React.useState({
    workspaceName: '',
  })

  return (
    <View style={styles.wrapper1}>
      <Grid style={styles.wrapper}>
        <Col style={styles.formBox}>
          <Grid style={styles.box}>
            <Row style={styles.content}>
              <Title style={styles.item}>ワークスペースを作成</Title>
              <Input
                autoFocus
                placeholder="ワークスペース名"
                style={styles.item}
                onChangeText={workspaceName => {
                  const newValue = {...values, workspaceName}
                  setValues(newValue)
                }}
                value={values.workspaceName}
                onSubmitEditing={() => onSubmit(values)}
              />
              <Button
                disabled={submitting}
                light
                fullwidth
                style={[styles.item]}
                onPress={() => {
                  onSubmit(values)
                }}>
                {submitting ? '送信中' : '作成'}
              </Button>
            </Row>
          </Grid>
        </Col>
      </Grid>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper1: {
    height,
  },
  wrapper: {
    backgroundColor: variables.colors.background.light,
    alignItems: 'center',
  },
  box: {
    width: 490,
    backgroundColor: variables.colors.background.white,
  },
  header: {},
  content: {
    padding: 32,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  item: {marginBottom: 12},
  formBox: {
    alignItems: 'center',
  },
  description: {
    backgroundColor: variables.colors.background.white,
  },
})

export default SetupPage
