interface Config {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  url: string
}

function createConfig(): Config {
  console.log('env', process.env.NODE_ENV)

  switch (process.env.NODE_ENV) {
    case 'local':
      return {
        apiKey: 'AIzaSyD6k3qdbOOKQJvv8VsdoO1pD5qZj5Bidrg',
        authDomain: 'classicc-dev.firebaseapp.com',
        databaseURL: 'https://classicc-dev.firebaseio.com',
        projectId: 'classicc-dev',
        storageBucket: 'classicc-dev.appspot.com',
        messagingSenderId: '624209892593',
        appId: '1:624209892593:web:87ae548fa06094923fc550',
        url: 'http://localhost:8080',
      }
    case 'develop':
      return {
        apiKey: 'AIzaSyD6k3qdbOOKQJvv8VsdoO1pD5qZj5Bidrg',
        authDomain: 'classicc-dev.firebaseapp.com',
        databaseURL: 'https://classicc-dev.firebaseio.com',
        projectId: 'classicc-dev',
        storageBucket: 'classicc-dev.appspot.com',
        messagingSenderId: '624209892593',
        appId: '1:624209892593:web:87ae548fa06094923fc550',
        url: 'https://dev.classicc.app',
      }
    case 'production':
      return {
        apiKey: 'AIzaSyD6k3qdbOOKQJvv8VsdoO1pD5qZj5Bidrg',
        authDomain: 'classicc-dev.firebaseapp.com',
        databaseURL: 'https://classicc-dev.firebaseio.com',
        projectId: 'classicc-dev',
        storageBucket: 'classicc-dev.appspot.com',
        messagingSenderId: '624209892593',
        appId: '1:624209892593:web:87ae548fa06094923fc550',
        url: 'https://classicc.app',
      }
  }

  return {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    url: '',
  }
}

export default createConfig()
