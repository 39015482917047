import {combineReducers} from 'redux'
import LoginPageReducer from './LoginPageReducer'
import SetupWorkspacePageReducer from './SetupWorkspacePageReducer'
import NotePageReducer from './NotePageReducer'

export default combineReducers({
  LoginPage: LoginPageReducer,
  SetupWorkspacePage: SetupWorkspacePageReducer,
  NotePage: NotePageReducer,
})
