import * as React from 'react'
import * as variables from '../../../variables'
import {
  Controlled as CodeMirror,
  IControlledCodeMirror,
} from 'react-codemirror2'
import styled from 'styled-components'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/gfm/gfm.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/clojure/clojure.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/dart/dart.js'
import 'codemirror/mode/diff/diff.js'
import 'codemirror/mode/django/django.js'
import 'codemirror/mode/dockerfile/dockerfile.js'
import 'codemirror/mode/elm/elm.js'
import 'codemirror/mode/erlang/erlang.js'
import 'codemirror/mode/gherkin/gherkin.js'
import 'codemirror/mode/go/go.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/handlebars/handlebars.js'
import 'codemirror/mode/haskell/haskell.js'
import 'codemirror/mode/jinja2/jinja2.js'
import 'codemirror/mode/julia/julia.js'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/mode/nginx/nginx.js'
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/pug/pug.js'
import 'codemirror/mode/ruby/ruby.js'
import 'codemirror/mode/sass/sass.js'
import 'codemirror/mode/shell/shell.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/mode/stylus/stylus.js'
import 'codemirror/mode/swift/swift.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/vue/vue.js'
import 'codemirror/mode/rust/rust.js'
import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/addon/edit/continuelist.js'
import 'codemirror/addon/edit/indentlist.js'

interface Props {
  value: string
  onBeforeChange: (
    editor: IControlledCodeMirror,
    data: object,
    value: string,
  ) => void
}

function MarkdownEditor({value, onBeforeChange}: Props) {
  return (
    <StyledEditor
      value={value}
      onBeforeChange={onBeforeChange}
      options={{
        mode: 'gfm',
        gitHubSpice: false,
        highlightFormatting: false,
        lineWrapping: true,
        extraKeys: {
          Enter: 'newlineAndIndentContinueMarkdownList',
          Tab: 'autoIndentMarkdownList',
          'Shift-Tab': 'autoUnindentMarkdownList',
        },
      }}
    />
  )
}

const StyledEditor = styled(CodeMirror)`
  .CodeMirror {
    background-color: #fff;
    height: 100%;
    font-family: ${variables.font.family};
    font-size: ${variables.size.font[4]};
    padding: ${variables.size.page.marginForWysywig}px;
    min-height: 100vh;
    width: ${variables.size.page.width}px;
    box-sizing: border-box;
  }

  .cm-header {
    color: black;
  }

  .cm-header-1 {
    font-size: ${variables.size.font[1]};
  }

  .cm-header-2 {
    font-size: ${variables.size.font[2]};
  }

  .cm-header-3 {
    font-size: ${variables.size.font[3]};
  }

  .cm-header-4 {
    font-size: ${variables.size.font[4]};
  }

  .cm-header-5 {
    font-size: ${variables.size.font[5]};
  }

  .cm-header-6 {
    font-size: ${variables.size.font[6]};
  }

  .cm-image,
  .cm-image-alt-text,
  .cm-url {
    font-size: ${variables.size.font[5]};
  }

  .cm-variable-2 {
    color: ${variables.colors.text.dark};
  }
`

export default MarkdownEditor
