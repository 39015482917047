class Workspace implements IWorkspace {
  public id: string | null
  public name: string
  public owner: string
  public type: 'personal'
  public createdAt?: Date
  public updatedAt?: Date

  static of(
    id: string | null,
    name: string,
    owner: string,
    type: 'personal',
    createdAt?: Date,
    updatedAt?: Date,
  ) {
    const workspace = new Workspace()

    workspace.id = id
    workspace.name = name
    workspace.owner = owner
    workspace.type = type
    workspace.createdAt = createdAt
    workspace.updatedAt = updatedAt

    return workspace
  }
}

export default Workspace
