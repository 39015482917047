import * as Actions from '../../../Modules/Actions'
import * as mutators from './mutators'

const initialState: SetupWorkspacePageState = {
  submitting: false,
}

function SetupWorkspacePageReducer(
  state: SetupWorkspacePageState = initialState,
  action: any,
) {
  switch (action.type) {
    case Actions.REQUEST_CREATE_WORKSPACES:
      return mutators.submitting(state)
    case Actions.FINISH_CREATE_WORKSPACES:
      return mutators.submitting(state)
  }

  return state
}

export default SetupWorkspacePageReducer
