import * as React from 'react'
import {
  Text as NativeText,
  StyleSheet,
  TextProps as NativeTextProps,
} from 'react-native'
import * as variables from '../../variables'

export interface TextProps extends NativeTextProps {
  children: string
  style?: object
}

function Text({style = {}, children, ...rest}: TextProps) {
  return (
    <NativeText style={[styles.label, style]} {...rest}>
      {children}
    </NativeText>
  )
}

const styles = StyleSheet.create({
  label: {
    paddingTop: 4,
    color: variables.colors.text.black,
  },
})

export default Text
