class Page implements IPage {
  id: string | null
  workspaceId: string
  noteId: string
  html: string
  text: string
  version: number
  pageNumber: number
  createdAt?: Date
  updatedAt?: Date

  static of(
    workspaceId: string,
    noteId: string,
    id: string | null,
    html: string,
    text: string,
    version: number,
    pageNumber: number,
    createdAt?: Date,
    updatedAt?: Date,
  ) {
    const page = new Page()

    page.workspaceId = workspaceId
    page.noteId = noteId
    page.id = id
    page.html = html
    page.text = text
    page.version = version
    page.pageNumber = pageNumber
    page.createdAt = createdAt || undefined
    page.updatedAt = updatedAt || undefined

    return page
  }
}

export default Page
