class Note implements INote {
  public id: string
  public title: string
  public workspaceId: string
  public pageCount: number
  public createdAt?: Date
  public updatedAt?: Date

  static of(
    id: string,
    title: string,
    workspaceId: string,
    pageCount: number,
    createdAt?: Date,
    updatedAt?: Date,
  ) {
    const note = new Note()

    note.id = id
    note.title = title
    note.pageCount = pageCount
    note.workspaceId = workspaceId
    note.createdAt = createdAt || undefined
    note.updatedAt = updatedAt || undefined

    return note
  }
}

export default Note
