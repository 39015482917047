import * as React from 'react'
import firebase from '../Services/Firebase.web'
import * as SessionModule from '../Modules/SessionModule'
import {connect} from 'react-redux'
import {AsyncStorage} from 'react-native'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Login} from '../Entities'

export interface AuthProps {
  stateChange: (user: any) => void
  requestSignIn: () => void
  finishSignIn: () => void
  initialize: () => Promise<InitializeResult>
  children: any
  userId: string
}

function Auth({
  stateChange,
  requestSignIn,
  finishSignIn,
  children,
  history,
  userId,
  initialize,
}: AuthProps & RouteComponentProps) {
  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      stateChange(user === null ? null : Login.of(user.uid))
    })

    return function cleanUp() {
      unsubscribe()
    }
  }, [stateChange, userId])

  React.useEffect(() => {
    async function signInWithEmailLink() {
      const email = await AsyncStorage.getItem('emailForSignIn')

      if (email) {
        try {
          requestSignIn()
          await firebase.auth().signInWithEmailLink(email, window.location.href)
          await AsyncStorage.removeItem('emailForSignIn')

          initialize().then(({workspace, note}: InitializeResult) => {
            if (workspace === null || note === null) {
              history.push('/setup_workspace')
            } else {
              history.push(`/${workspace.id}/${note.id}`)
            }
          })
        } catch (e) {
          console.log('signInWithEmailLink', e)
        }
        finishSignIn()
      }
    }

    signInWithEmailLink()
  }, [finishSignIn, history, initialize, requestSignIn, userId])

  return children
}

const mapStateToProps = (state: any) => {
  const {user}: SessionState = state.app.Session

  return {
    userId: user === null ? '' : user.id,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    stateChange: (user: any) => dispatch(SessionModule.stateChange(user)),
    requestSignIn: () => dispatch(SessionModule.requestSignInConfirm()),
    finishSignIn: () => dispatch(SessionModule.finishSignInConfirm()),
    initialize: () => dispatch(SessionModule.initialize()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Auth),
)
