import * as NoteService from '../Services/FirebaseNote.web'
import * as uuid from 'uuid'
import {Note} from '../Entities'

export function createUUID() {
  return uuid.v4()
}

export function getInitialWorkspace(
  workspaceId: string,
): Promise<INote | null> {
  return NoteService.getLastUpdateEntity(workspaceId)
}

export async function createFirstNote(
  workspaceId: string,
): Promise<INote | Error> {
  return createNote(workspaceId, '新しいノート')
}

export async function createNote(
  workspaceId: string,
  title: string,
): Promise<INote | Error> {
  const note = Note.of(createUUID(), title, workspaceId, 1)
  const result = await NoteService.save(note)

  if (result === null) {
    return note
  }

  return result
}
