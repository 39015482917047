import {db, timestamp} from './Firebase.web'
import {Workspace} from '../Entities'

const collectionName = 'workspaces'

export async function getLastUpdateEntity(
  userId: string,
): Promise<IWorkspace | null> {
  const workspaces = await db
    .collection(collectionName)
    .where('owner', '==', userId)
    .orderBy('updatedAt', 'desc')
    .limit(1)
    .get()

  if (workspaces.empty) {
    return null
  }

  const workspace = workspaces.docs[0]

  return Workspace.of(
    workspace.id,
    workspace.data().name,
    workspace.data().owner,
    workspace.data().type,
    workspace.data().createdAt.toDate(),
    workspace.data().updatedAt.toDate(),
  )
}

export async function get(id: string): Promise<IWorkspace | null> {
  const workspace = await db
    .collection(collectionName)
    .doc(id)
    .get()

  if (!workspace.exists) {
    return null
  }

  const {name, owner, type, createdAt, updatedAt} = workspace.data() || {}

  return Workspace.of(
    workspace.id,
    name,
    owner,
    type,
    createdAt.toDate(),
    updatedAt.toDate(),
  )
}

export async function create(
  workspace: IWorkspace,
): Promise<IWorkspace | null> {
  const created = await db.collection(collectionName).add({
    name: workspace.name,
    owner: workspace.owner,
    type: workspace.type,
    createdAt: timestamp(),
    updatedAt: timestamp(),
  })

  return get(created.id)
}
