class Login implements ILogin {
  public id: string

  static of(id: string) {
    const login = new Login()

    login.id = id

    return login
  }
}

export default Login
