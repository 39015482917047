import * as React from 'react'
import {View, Text} from 'react-native'
import styled from 'styled-components'
import * as variable from '../../../variables'

export interface NoteTitleProps {
  title: string
}

function NoteTitle({title}: NoteTitleProps) {
  return (
    <View>
      <StyledText accessibilityRole="header">{title}</StyledText>
    </View>
  )
}

const StyledText = styled(Text)`
  font-size: ${variable.size.font[4]};
  font-weight: 500;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
`

export default NoteTitle
