import env from '@config/env'
import * as firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: env.apiKey,
  authDomain: env.authDomain,
  databaseURL: env.databaseURL,
  projectId: env.projectId,
  storageBucket: env.storageBucket,
  messagingSenderId: env.messagingSenderId,
  appId: env.appId,
}

firebase.initializeApp(firebaseConfig)

export default firebase

export function timestamp() {
  return firebase.firestore.FieldValue.serverTimestamp()
}

export const db = firebase.firestore()
