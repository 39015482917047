import * as React from 'react'
import {connect} from 'react-redux'
import {View, Text} from 'react-native'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import * as SessionModule from '../Modules/SessionModule'

interface AppInitializerProps {
  session: SessionState
  children: any
  initialize: () => Promise<InitializeResult>
}

function AppInitializer({
  session,
  children,
  location,
  history,
  initialize,
}: AppInitializerProps & RouteComponentProps) {
  React.useEffect(() => {
    if (!session.loading) {
      if (session.user === null && location.pathname !== '/login') {
        history.push('/login')
      } else if (session.user !== null && location.pathname === '/login') {
        history.push('/')
      }
    }

    if (!session.loading && session.user !== null && session.initializing) {
      initialize().then(({workspace, note}: InitializeResult) => {
        if (workspace === null || note === null) {
          history.push('/setup_workspace')
        } else {
          history.push(`/${workspace.id}/${note.id}`)
        }
      })
    }
  })

  if (session.loading || session.initializing) {
    return (
      <View>
        <Text>loading...</Text>
      </View>
    )
  }

  return children
}

const mapStateToProps = (state: any) => {
  const {
    app: {Session},
  } = state

  return {
    session: Session,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  initialize: () => dispatch(SessionModule.initialize()),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppInitializer),
)
