import * as React from 'react'
import styled from 'styled-components'
import {FixedSizeList as List, areEqual} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import * as variables from '../../variables'

interface Props {
  pageCount: number
  pageComponent: any
}

class Note extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.renderItem = this.renderItem.bind(this)
  }

  render() {
    return (
      <NoteWrapper>
        <AutoSizer>
          {({height, width}) => (
            <List
              height={height}
              itemCount={this.props.pageCount}
              itemSize={
                width < variables.size.page.width
                  ? width
                  : variables.size.page.width
              }
              layout="horizontal"
              width={width}>
              {this.renderItem()}
            </List>
          )}
        </AutoSizer>
      </NoteWrapper>
    )
  }

  renderItem() {
    return React.memo(({index, style}: any) => {
      return (
        <PageWrapper style={style}>
          <this.props.pageComponent index={index} />
        </PageWrapper>
      )
    }, areEqual)
  }
}

const NoteWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

const PageWrapper = styled.div`
  height: calc(100vh - ${variables.size.page.toolbarHeight});
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export default Note
