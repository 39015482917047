import * as React from 'react'
import {Calendar as NativeCalendar, LocaleConfig} from 'react-native-calendars'
// import Icon from './Icon'
import {StyleSheet, Text} from 'react-native'

LocaleConfig.locales.ja = {
  monthNames: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  monthNamesShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  dayNames: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
}
LocaleConfig.defaultLocale = 'ja'

function Calendar() {
  return (
    <NativeCalendar
      style={styles.calendar}
      monthFormat={'yyyy MM'}
      // renderArrow={direction =>
      //   direction === 'left' ? (
      //     <Icon name="arrowBack" />
      //   ) : (
      //     <Icon name="arrowForward" />
      //   )
      // }
      renderArrow={direction => <Text>{direction === 'left' ? '<' : '>'}</Text>}
    />
  )
}

const styles = StyleSheet.create({
  calendar: {
    width: '99%',
  },
})

export default Calendar
