import * as React from 'react'
import {NoteMenuTab} from '../atoms'
import styled from 'styled-components'
import {View} from 'react-native'

interface Props {
  onChange: (value: NoteMenu) => void
}

interface State {
  value: NoteMenu
}

class NoteMenuTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.setValue = this.setValue.bind(this)
    this.state = {
      value: 'index',
    }
  }

  render() {
    return (
      <Wrapper>
        <NoteMenuTab
          active={this.state.value === 'index'}
          width={55}
          value="index"
          onPress={this.setValue}>
          目次
        </NoteMenuTab>
        <NoteMenuTab
          active={this.state.value === 'setting'}
          width={55}
          value="setting"
          onPress={this.setValue}>
          設定
        </NoteMenuTab>
        <NoteMenuTab
          active={this.state.value === 'calendar'}
          width={85}
          value="calendar"
          onPress={this.setValue}>
          カレンダー
        </NoteMenuTab>
      </Wrapper>
    )
  }

  setValue(value: NoteMenu) {
    this.setState({value})
    this.props.onChange(value)
  }
}

const Wrapper = styled(View)`
  height: 40px;
  flex-direction: row;
  padding: 0 8px;
`

export default NoteMenuTabs
