import * as React from 'react'
import {TouchableOpacity as NativeButton, StyleSheet, View} from 'react-native'
import * as variables from '../../variables'
import {Text} from '.'

interface ButtonProps {
  style?: object
  children: any
  disabled?: boolean
  white?: boolean
  light?: boolean
  dark?: boolean
  black?: boolean
  info?: boolean
  link?: boolean
  success?: boolean
  warning?: boolean
  danger?: boolean
  primary?: boolean
  fullwidth?: boolean
  onPress?: () => void
}

export default function Button({
  style = {},
  children,
  disabled = false,
  white = false,
  light = false,
  dark = false,
  black = false,
  info = false,
  link = false,
  success = false,
  warning = false,
  danger = false,
  primary = false,
  fullwidth = false,
  onPress = () => {},
}: ButtonProps) {
  return (
    <View
      style={[styles.wrapper, style, fullwidth ? styles.wrapperFullwidth : {}]}>
      <NativeButton
        style={[
          styles.button,
          white ? styles.white : {},
          light ? styles.light : {},
          dark ? styles.dark : {},
          black ? styles.black : {},
          info ? styles.info : {},
          link ? styles.link : {},
          success ? styles.success : {},
          warning ? styles.warning : {},
          danger ? styles.danger : {},
          primary ? styles.primary : {},
        ]}
        disabled={disabled}
        onPress={onPress}
        accessibilityRole="button">
        <Text
          style={[
            styles.text,
            dark ? styles.darkText : {},
            black ? styles.blackText : {},
            info ? styles.infoText : {},
            link ? styles.linkText : {},
            success ? styles.successText : {},
            warning ? styles.warningText : {},
            danger ? styles.dangerText : {},
            primary ? styles.primaryText : {},
          ]}>
          {children}
        </Text>
      </NativeButton>
    </View>
  )
}

const commonButtonStyle = {borderWidth: 0}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  wrapperFullwidth: {
    alignItems: 'stretch',
    textAlign: 'center',
  },
  button: {
    borderRadius: variables.size.radius,
    backgroundColor: variables.colors.background.white,
    borderColor: variables.colors.text.greyLight,
    borderWidth: StyleSheet.hairlineWidth,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 12,
    paddingRight: 12,
    height: 40,
    justifyContent: 'center',
  },
  text: {
    fontFaimly: variables.font.family,
    color: variables.colors.text.blackTer,
    paddingTop: 2,
  },
  white: {
    ...commonButtonStyle,
  },
  light: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.light,
  },
  dark: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.greyDark,
  },
  darkText: {
    color: variables.colors.background.white,
  },
  black: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.black,
  },
  blackText: {
    color: variables.colors.background.white,
  },
  info: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.info,
  },
  infoText: {
    color: variables.colors.text.info,
  },
  success: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.success,
  },
  successText: {
    color: variables.colors.text.success,
  },
  warning: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.warning,
  },
  warningText: {
    color: variables.colors.text.warning,
  },
  danger: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.danger,
  },
  dangerText: {
    color: variables.colors.text.danger,
  },
  link: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.link,
  },
  linkText: {
    color: variables.colors.text.link,
  },
  primary: {
    ...commonButtonStyle,
    backgroundColor: variables.colors.background.primary,
  },
  primaryText: {
    color: variables.colors.text.primary,
  },
})
