import * as React from 'react'
import {StyleSheet} from 'react-native'
import * as variables from '../../variables'
import {Text} from '.'
import {TextProps} from './Text'

interface LinkProps extends TextProps {
  children: string
  href: string
  target?: '_blank' | '_self'
}

function Link({href, children, target = '_self'}: LinkProps) {
  return (
    // @ts-ignore
    <Text
      accessibilityRole="link"
      style={styles.label}
      href={href}
      target={target}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  label: {
    color: variables.colors.text.link,
  },
})

export default Link
