import * as React from 'react'
import styled from 'styled-components'
import * as variables from '../../../variables'

interface Props {
  html: string
}

function Paper({html}: Props) {
  return <StyledDiv dangerouslySetInnerHTML={{__html: html}} />
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  min-height: 100vh;
  font-size: ${variables.size.font[4]};
  box-sizing: border-box;
  padding: ${variables.size.page.margin}px;

  & > *:last-child {
    margin-bottom: 50vh;
  }

  img {
    width: 100%;
  }

  width: ${variables.size.page.width}px;

  @media (max-width: 768px) {
    width: 100vw;
  }
`

export default Paper
