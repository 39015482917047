import * as Actions from '../../../Modules/Actions'

const initialState: NotePageState = {
  note: null,
  loading: true,
}

function NotePageReducer(state: NotePageState = initialState, action: any) {
  switch (action.type) {
    case Actions.START_LISTEN_NOTE:
      return loading(state)

    case Actions.UPDATE_NOTE:
      return update(state, action.payload.note)
  }

  return state
}

function update(state: NotePageState, note: INote) {
  return {...state, note, loading: false}
}

function loading(state: NotePageState) {
  return {...state, loading: true}
}

export default NotePageReducer
