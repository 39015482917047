//
// Colors
//

export const colors = {
  text: {
    white: '#ffffff',
    black: '#0C0C0C',
    light: '#F5F5F5',
    dark: '#393939',
    primary: '#ffffff',
    info: '#ffffff',
    link: '#4D72D8',
    success: '#ffffff',
    warning: '#0C0C0C',
    danger: '#ffffff',
    blackBis: '#191919',
    blackTer: '#292929',

    // Gray（↑濃い, ↓薄い）
    greyDarker: '#393939',
    greyDark: '#4B4B4B',
    grey: '#797979',
    greyLight: '#B4B4B4',
    greyLighter: '#DADADA',
    whiteTer: '#F5F5F5',
    whiteBis: '#FAFAFA',
  },
  background: {
    white: '#ffffff',
    black: '#0C0C0C',
    light: '#F5F5F5',
    dark: '#393939',
    primary: '#76CFB2',
    info: '#5C9AEA',
    link: '#4D72D8',
    success: '#79D068',
    warning: '#F6DC62',
    danger: '#DC3B5F',
    blackBis: '#191919',
    blackTer: '#292929',

    // Gray（↑濃い, ↓薄い）
    greyDarker: '#393939',
    greyDark: '#4B4B4B',
    grey: '#797979',
    greyLight: '#B4B4B4',
    greyLighter: '#DADADA',
    whiteTer: '#F5F5F5',
    whiteBis: '#FAFAFA',
  },
}

export const size = {
  font: {
    1: '3rem',
    2: '2.5rem',
    3: '2rem',
    4: '1.5rem',
    5: '1.25rem',
    6: '1rem',
    7: '0.75rem',
  },
  page: {
    width: 700,
    innerWidth: 700 - 24 * 2,
    margin: 24,
    marginForWysywig: 24 - 4,
    toolbarHeight: '40px',
  },
  radius: 4,
}

export const font = {
  family: "'Noto Sans Japanese', sans-serif",
}
