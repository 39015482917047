import * as WorkspaceService from '../Services/FirebaseWorkspace.web'

export function getInitialWorkspace(
  userId: string,
): Promise<IWorkspace | null> {
  return WorkspaceService.getLastUpdateEntity(userId)
}

export function create(workspace: IWorkspace): Promise<IWorkspace | null> {
  return WorkspaceService.create(workspace)
}
