import * as React from 'react'
import {TextInput, StyleSheet, TextInputProps} from 'react-native'
import * as variables from '../../../variables'

interface Props extends TextInputProps {
  placeholder?: any
  defaultValue?: string
  style?: object
  placeholderStyle?: object | null
}

function Input({
  textContentType = 'none',
  placeholder = '',
  defaultValue = '',
  style = {},
  placeholderStyle = null,
  ...rest
}: Props) {
  const [value, setValue] = React.useState(defaultValue)
  const _placeholderStyle =
    placeholderStyle === null ? styles.placeholder : placeholderStyle

  return (
    <TextInput
      style={[styles.input, style, value.length === 0 ? _placeholderStyle : {}]}
      placeholder={placeholder}
      placeholderTextColor={variables.colors.text.greyLight}
      value={value}
      onChangeText={text => setValue(text)}
      textContentType={textContentType}
      secureTextEntry={textContentType === 'password'}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: variables.colors.background.white,
    height: 40,
    width: '100%',
    fontSize: 16,
    fontFamily: variables.font.family,
    padding: 12,
    color: variables.colors.text.greyDarker,
    borderBottomColor: variables.colors.background.grey,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  placeholder: {
    fontSize: 16,
  },
})

export default Input
