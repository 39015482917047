import {timestamp, db} from './Firebase.web'
import * as FirebaseNote from './FirebaseNote.web'
import {Page} from '../Entities'
import * as uuid from 'uuid'

db.enablePersistence({experimentalTabSynchronization: true}).catch(function(
  err,
) {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
})

export function createPageID() {
  return uuid.v4()
}

export async function save(page: IPage): Promise<null | Error> {
  try {
    await db
      .collection('pages')
      .doc(page.id === null ? createPageID() : page.id)
      .set({
        workspaceId: page.workspaceId,
        noteId: page.noteId,
        html: page.html,
        text: page.text,
        version: page.version,
        pageNumber: page.pageNumber,
        createdAt: page.createdAt || timestamp(),
        updatedAt: page.updatedAt || timestamp(),
      })
  } catch (e) {
    return e
  }

  return null
}

export async function get(pageId: string) {
  const snap = await db
    .collection('pages')
    .doc(pageId)
    .get()

  if (!snap.exists) {
    return null
  }

  const data = snap.data() || {}

  return Page.of(
    data.workspaceId,
    data.noteId,
    snap.id,
    data.html,
    data.text,
    data.version,
    data.pageNumber,
    data.createdAt,
    data.updatedAt,
  )
}

export async function update(pageId: string, values: object) {
  await db
    .collection('pages')
    .doc(pageId)
    .update(values)
}

export function createAndListenPage(
  workspaceId: string,
  noteId: string,
  pageNumber: number,
  callback: (page: IPage) => void,
): Function {
  return db
    .collection('pages')
    .where('workspaceId', '==', workspaceId)
    .where('noteId', '==', noteId)
    .where('pageNumber', '==', pageNumber)
    .onSnapshot(snapshots => {
      if (snapshots.empty) {
        Promise.all([
          save(Page.of(workspaceId, noteId, null, '', '', 0, pageNumber)),
          FirebaseNote.incrementPageNumber(noteId),
        ]).then(([res]) => {
          console.log({res})
        })

        return
      }

      const snap = snapshots.docs[0]

      const p = Page.of(
        snap.data().workspaceId,
        snap.data().noteId,
        snap.id,
        snap.data().html,
        snap.data().text,
        snap.data().version,
        snap.data().pageNumber,
        snap.data().createdAt,
        snap.data().updatedAt,
      )

      callback(p)
    })
}
