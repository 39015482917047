import * as Actions from '../../../Modules/Actions'
import * as mutators from './mutators'

const initialState: LoginPageState = {
  submitting: false,
  confirming: false,
}

function LoginPageReducer(state: LoginPageState = initialState, action: any) {
  switch (action.type) {
    case Actions.REQUEST_LOGIN:
      return mutators.submitting(state)
    case Actions.FINISH_LOGIN:
      return mutators.submitted(state)
    case Actions.REQUEST_LOGIN_CONFIRM:
      return confirming(state)
    case Actions.FINISH_LOGIN_CONFIRM:
      return confirmed(state)
  }

  return state
}

function confirming(state: LoginPageState) {
  return {...state, confirming: true}
}

function confirmed(state: LoginPageState) {
  return {...state, confirming: false}
}

export default LoginPageReducer
