import {connect} from 'react-redux'
import NoteInfo from '@comp/molecules/NoteInfo'

const mapStateToProps = ({
  ui: {
    NotePage: {note},
  },
}: AppState) => ({
  title: note === null ? '' : note.title,
})

export default connect(
  mapStateToProps,
  null,
)(NoteInfo)
