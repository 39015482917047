import * as Actions from './Actions'
import * as WorkspaceRepository from '../Repositories/WorkspaceRepository'
import * as NoteRepository from '../Repositories/NoteRepository'
import {Workspace} from '../Entities'

export function createFirstWorkspace(workspaceName: string) {
  return async (
    dispatch: any,
    getState: () => AppState,
  ): Promise<InitializeResult | void> => {
    dispatch({type: Actions.REQUEST_CREATE_WORKSPACES})

    const {user} = getState().app.Session

    if (user === null) {
      dispatch({
        type: Actions.SHOW_ERROR_MESSAGE,
        payload: new Error('システムエラー'),
      })
      return
    }

    const workspace = await WorkspaceRepository.create(
      Workspace.of(null, workspaceName, user.id, 'personal'),
    )

    if (workspace && workspace.id !== null) {
      const note = await NoteRepository.createFirstNote(workspace.id)

      if (note instanceof Error) {
        dispatch({
          type: Actions.SHOW_ERROR_MESSAGE,
          payload: new Error('システムエラー'),
        })
        return
      }

      return {workspace, note}
    }

    dispatch({type: Actions.FINISH_CREATE_WORKSPACES})
  }
}
