import * as React from 'react'
import styled from 'styled-components'
import * as variables from '../../variables'

interface Props {
  name:
    | 'menu'
    | 'toggle'
    | 'info'
    | 'arrowDropdown'
    | 'arrowDropright'
    | 'arrowBack'
    | 'arrowForward'
    | 'bookmark'
    | 'grid'
    | 'cube'
    | 'book'
    | 'checkBoxOff'
    | 'checkBoxOn'
  iconSize?: number
  buttonSize?: number
  color?: string
  button?: boolean
  margin?: number
}

function Icon({
  name,
  iconSize = 18,
  buttonSize = 40,
  color = variables.colors.text.grey,
  button = false,
  margin = 8,
}: Props) {
  return button ? (
    <StyledButton style={{width: buttonSize, height: buttonSize}}>
      <StyledIcon
        className={nameToIconName[name]}
        style={{fontSize: iconSize, color}}
      />
    </StyledButton>
  ) : (
    <StyledIcon
      className={nameToIconName[name]}
      style={{fontSize: iconSize, color, padding: margin}}
    />
  )
}

const nameToIconName = {
  menu: 'ion-ios-more',
  toggle: 'ion-ios-swap',
  info: 'ion-ios-information-circle-outline',
  arrowDropdown: 'ion-md-arrow-dropdown',
  arrowDropright: 'ion-md-arrow-dropright',
  arrowBack: 'ion-md-arrow-back',
  arrowForward: 'ion-md-arrow-forward',
  bookmark: 'ion-ios-bookmark',
  grid: 'ion-md-grid',
  cube: 'ion-md-cube',
  book: 'ion-md-book',
  checkBoxOff: 'ion-ios-square-outline',
  checkBoxOn: 'ion-ios-checkmark',
}

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  &:hover {
    color: ${variables.colors.text.greyLight};
    background-color: ${variables.colors.text.light};
  }
`

const StyledIcon = styled.i`
  padding: 0 8px;
  border-radius: 5px;
  background-color: transparent;
`

export default Icon
