import {createStore, compose, applyMiddleware} from 'redux'
import rootReducer from './reducers'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

export default function configureStore() {
  const composeEnhancers =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(thunk, logger)),
  )
}
