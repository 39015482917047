import * as React from 'react'
import {PageToolbar} from '../molecules'
import {Paper, MarkdownEditor} from '../atoms'
import styled from 'styled-components'
import {View} from 'react-native'
import * as variables from '../../variables'
import {IControlledCodeMirror} from 'react-codemirror2'

export interface Props {
  /** マークダウン形式の値 */
  value?: string
  /** htmlテキスト */
  html: string
  /** 編集中？ true=編集中 */
  editing: boolean
  onBeforeChange: (
    editor: IControlledCodeMirror,
    data: object,
    value: string,
  ) => void
}

function Page({html, value = '', editing, onBeforeChange}: Props) {
  return (
    <Wrapper>
      <PageToolbar />
      {editing ? (
        <MarkdownEditor value={value} onBeforeChange={onBeforeChange} />
      ) : (
        <PaperWrapper>
          <Paper html={html} />
        </PaperWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(View)`
  flex-direction: column;
`

const PaperWrapper = styled(View)`
  height: calc(100vh - ${variables.size.page.toolbarHeight});
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100vw;
  }
`

export default Page
