import * as React from 'react'
import {Icon, Link} from '..'
import {TouchableOpacity, View, StyleSheet, Text} from 'react-native'
import * as variables from '../../../variables'

interface CheckBoxProps {
  label?: string
  link?: string
  onChange?: (value: boolean) => void
}

function CheckBox({label, link, onChange}: CheckBoxProps) {
  const [value, setValue] = React.useState(false)
  return (
    <View style={styles.wrapper}>
      <TouchableOpacity
        onPress={() => {
          const newValue = !value
          setValue(newValue)
          if (onChange) {
            onChange(newValue)
          }
        }}
        style={styles.checkbox}>
        <Icon
          name={value ? 'checkBoxOn' : 'checkBoxOff'}
          iconSize={30}
          color={
            value
              ? variables.colors.background.success
              : variables.colors.background.greyLighter
          }
        />
      </TouchableOpacity>
      {label !== undefined && link !== undefined && (
        <Link href={link} target="_blank">
          {label}
        </Link>
      )}
      {label !== undefined && link === undefined && <Text>{label}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  checkbox: {
    width: 40,
  },
})

export default CheckBox
