import * as React from 'react'
import * as variables from '../../../variables'
import styled from 'styled-components'

interface Props {
  children?: any
}

function ToolbarContainer({children}: Props) {
  return (
    <ToolbarWrapper>
      <Toolbar>{children}</Toolbar>
    </ToolbarWrapper>
  )
}

const ToolbarWrapper = styled.div`
  background-color: ${variables.colors.background.white};
  boxsizing: border-box;
  width: ${variables.size.page.width}px;

  @media (max-width: 768px) {
    width: 100vw;
  }
`

const Toolbar = styled.div`
  height: ${variables.size.page.toolbarHeight};
  background-color: ${variables.colors.background.white};
  boxsizing: border-box;
  border-bottom-style: solid;
  border-bottom-color: ${variables.colors.background.light};
  border-bottom-width: 0.5px;
  margin: ${variables.size.page.margin}px;
  margin-bottom: 0;
  margin-top: 0;
  flexdirection: row;
  width: ${variables.size.page.innerWidth}px;
  display: flex;

  @media (max-width: 768px) {
    width: calc(100vw - ${variables.size.page.margin * 2}px);
  }
`

export default ToolbarContainer
