import * as React from 'react'
import {Row, Grid, Col} from 'react-native-easy-grid'
import {StyleSheet, Dimensions, View} from 'react-native'
import * as variables from '../../variables'
import {Title, Input, Button} from '../atoms'
import CheckBox from '../atoms/Form/CheckBox'

interface LoginTemplateProps {
  title: string
  subTitle: string
  buttonText: string
  submitting?: boolean
  confirming?: boolean
  onLoad: () => void
  onSubmit: (value: LoginValue) => void
}

const {height} = Dimensions.get('window')

function LoginTemplate({
  title,
  buttonText,
  submitting,
  confirming,
  onSubmit,
  onLoad,
}: LoginTemplateProps) {
  const [values, setValues] = React.useState({
    email: '',
    accept: false,
  })

  React.useEffect(() => {
    onLoad()
  })

  return (
    <View style={styles.wrapper1}>
      <Grid style={styles.wrapper}>
        <Col style={styles.formBox}>
          <Grid style={styles.box}>
            <Row style={styles.content}>
              <Title style={styles.item}>{title}</Title>
              {confirming ? null : (
                <>
                  <Input
                    autoFocus
                    placeholder="メールアドレス"
                    style={styles.item}
                    textContentType="emailAddress"
                    autoCompleteType="email"
                    onChangeText={email => {
                      const newValue = {...values, email}
                      setValues(newValue)
                    }}
                    value={values.email}
                    onSubmitEditing={() => onSubmit(values)}
                  />
                  <CheckBox
                    label="利用規約に同意する"
                    link="/"
                    onChange={accept => {
                      const newValue = {...values, accept}
                      setValues(newValue)
                    }}
                  />
                  <Button
                    disabled={submitting || !values.accept}
                    light
                    fullwidth
                    style={[styles.item]}
                    onPress={() => {
                      onSubmit(values)
                    }}>
                    {submitting ? '送信中' : buttonText}
                  </Button>
                </>
              )}
            </Row>
          </Grid>
        </Col>
        {/* <Col style={styles.description} /> */}
      </Grid>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper1: {
    height,
  },
  wrapper: {
    backgroundColor: variables.colors.background.light,
    alignItems: 'center',
  },
  box: {
    width: 490,
    backgroundColor: variables.colors.background.white,
  },
  header: {},
  content: {
    padding: 32,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  item: {marginBottom: 12},
  formBox: {
    alignItems: 'center',
  },
  description: {
    backgroundColor: variables.colors.background.white,
  },
})

export default LoginTemplate
