import * as React from 'react'
import {View, FlatList, StyleSheet} from 'react-native'
import NoteIndexItem from './NoteIndexItem'

interface Props {}

class NoteIndex extends React.Component {
  constructor(props: Props) {
    super(props)
    this.renderItem = this.renderItem.bind(this)
  }

  render() {
    return (
      <View style={styles.menu} accessibilityRole="menu">
        <FlatList
          style={styles.list}
          data={[
            {
              menu:
                '市場創造型イノベーションのパワー市場創造型イノベーションのパワー',
              submenu: [
                {menu: 'イノベーションの種類'},
                {menu: '苦痛に潜む機会'},
                {menu: 'プル対プッシュ―2つの戦略'},
              ],
            },
            {
              menu: 'イノベーションと社会の繁栄',
              submenu: [
                {menu: 'アメリカを変えたイノベーション物語'},
                {menu: 'アジアの繁栄'},
                {menu: 'メキシコに見る効率化イノベーションの罠'},
              ],
            },
            {
              menu: 'イノベーションにできること',
              submenu: [
                {menu: 'イノベーションと制度の関係'},
                {menu: 'なぜ腐敗は「雇用」されつづけるのか'},
                {menu: 'インフラのジレンマ'},
              ],
            },
            {
              menu:
                '市場創造型イノベーションのパワー市場創造型イノベーションのパワー',
              submenu: [
                {menu: 'イノベーションの種類'},
                {menu: '苦痛に潜む機会'},
                {menu: 'プル対プッシュ―2つの戦略'},
              ],
            },
            {
              menu: 'イノベーションと社会の繁栄',
              submenu: [
                {menu: 'アメリカを変えたイノベーション物語'},
                {menu: 'アジアの繁栄'},
                {menu: 'メキシコに見る効率化イノベーションの罠'},
              ],
            },
            {
              menu: 'イノベーションにできること',
              submenu: [
                {menu: 'イノベーションと制度の関係'},
                {menu: 'なぜ腐敗は「雇用」されつづけるのか'},
                {menu: 'インフラのジレンマ'},
              ],
            },
            {
              menu:
                '市場創造型イノベーションのパワー市場創造型イノベーションのパワー',
              submenu: [
                {menu: 'イノベーションの種類'},
                {menu: '苦痛に潜む機会'},
                {menu: 'プル対プッシュ―2つの戦略'},
              ],
            },
            {
              menu: 'イノベーションと社会の繁栄',
              submenu: [
                {menu: 'アメリカを変えたイノベーション物語'},
                {menu: 'アジアの繁栄'},
                {menu: 'メキシコに見る効率化イノベーションの罠'},
              ],
            },
            {
              menu: 'イノベーションにできること',
              submenu: [
                {menu: 'イノベーションと制度の関係'},
                {menu: 'なぜ腐敗は「雇用」されつづけるのか'},
                {menu: 'インフラのジレンマ'},
              ],
            },
          ]}
          renderItem={this.renderItem}
        />
      </View>
    )
  }

  renderItem({item}: any) {
    const {menu, submenu} = item
    return <NoteIndexItem label={menu} submenu={submenu} />
  }
}

const styles = StyleSheet.create({
  menu: {
    flex: 1,
  },
  list: {
    paddingTop: 18,
  },
})

export default NoteIndex
