import * as React from 'react'
import {Text} from '.'

interface SubTitleProps {
  children: string
}

function SubTitle({children}: SubTitleProps) {
  return <Text>{children}</Text>
}

export default SubTitle
