import * as React from 'react'
import styled from 'styled-components'
import {Text, TouchableOpacity} from 'react-native'
import * as variables from '../../../variables'

interface Props {
  width: number
  children: any
  value: string
  onPress: (value: string) => void
}

interface ActiveProp {
  active: boolean
}

const margin = 10

function NoteMenuTab({
  width,
  children,
  active,
  value,
  onPress,
}: Props & ActiveProp) {
  return (
    <Wrapper
      style={{width: width - margin * 2}}
      active={active}
      onPress={() => onPress(value)}>
      <StyledText active={active}>{children}</StyledText>
    </Wrapper>
  )
}

const Wrapper = styled(TouchableOpacity)`
  height: 40px;
  margin-top: 1px;
  margin-left: ${margin}px;
  margin-right: ${margin}px;
  padding: 10px 0;
  border-bottom-color: ${variables.colors.background.info};
  border-bottom-width: ${(props: ActiveProp) => (props.active ? 3 : 0)}px;
  border-bottom-style: solid;
  text-align: center;
`

const StyledText = styled(Text)`
  font-size: ${variables.size.font[5]};
  color: ${(props: ActiveProp) =>
    props.active
      ? variables.colors.background.info
      : variables.colors.background.black};
`

export default NoteMenuTab
