export const SESSION_STATE_CHANGE = 'session/SESSION_STATE_CHANGE'
export const REQUEST_LOGIN = 'session/REQUEST_LOGIN'
export const FINISH_LOGIN = 'session/FINISH_LOGIN'
export const REQUEST_LOGIN_CONFIRM = 'session/REQUEST_LOGIN_CONFIRM'
export const FINISH_LOGIN_CONFIRM = 'session/FINISH_LOGIN_CONFIRM'
export const START_INITIALIZE = 'session/START_INITIALIZE'
export const FINISH_INITIALIZE = 'session/FINISH_INITIALIZE'
export const REQUEST_WORKSPACE_SETUP = 'REQUEST_WORKSPACE_SETUP'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'

//
// Workspace
//
export const CHANGE_WORKSPACE = 'workspace/CHANGE_WORKSPACE'
export const REQUEST_CREATE_WORKSPACES = 'workspace/REQUEST_CREATE_WORKSPACES'
export const FINISH_CREATE_WORKSPACES = 'workspace/FINISH_CREATE_WORKSPACES'

//
// Note
//

export const UPDATE_NOTE = 'note/UPDATE_NOTE'
export const START_LISTEN_NOTE = 'note/START_LISTEN_NOTE'
