import * as React from 'react'
import {LoginTemplate} from '../templates'

export interface LoginPageProps {
  submitting?: boolean
  confirming?: boolean
  onSubmit: (values: LoginValue) => void
  onLoad?: () => void
}

function LoginPage({
  submitting = false,
  confirming = false,
  onSubmit,
  onLoad = () => {},
}: LoginPageProps) {
  return (
    <LoginTemplate
      title="ログイン"
      subTitle="サブタイトル"
      buttonText="ログイン"
      submitting={submitting}
      confirming={confirming}
      onSubmit={onSubmit}
      onLoad={onLoad}
    />
  )
}

export default LoginPage
