import * as React from 'react'
import {Routes} from './Web'
import {Provider} from 'react-redux'
import configureStore from './Store/configureStore'
import './storybook/scss/app.scss'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

export default App
