import * as React from 'react'
import {View} from 'react-native'
import styled from 'styled-components'
import * as variables from '../../variables'
import {NoteIndex} from '../molecules'
import {Calendar} from '../atoms'

interface Props {
  NoteInfo: any
}

interface State {
  menu: NoteMenu
}

class SideMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = {menu: 'index'}
  }

  render() {
    const {NoteInfo} = this.props
    const {menu} = this.state
    return (
      <SideMenuWrapper>
        <NoteInfo onChange={this.onChange} />
        {menu === 'index' && <NoteIndex />}
        {menu === 'calendar' && <Calendar />}
      </SideMenuWrapper>
    )
  }

  onChange(menu: NoteMenu) {
    this.setState({menu})
  }
}

const SideMenuWrapper = styled(View)`
  width: 300px;
  height: 100vh;
  background-color: ${variables.colors.background.white};
  border-right-style: solid;
  border-right-width: 0.5px;
  border-right-color: ${variables.colors.background.greyLighter};

  @media (max-width: 768px) {
    width: 0px;
  }
`

export default SideMenu
