import * as React from 'react'
import {ToolbarContainer, Icon} from '../atoms'

function PageToolbar() {
  return (
    <ToolbarContainer>
      <Icon name="toggle" button />
      <Icon name="menu" button />
      <Icon name="info" button />
    </ToolbarContainer>
  )
}

export default PageToolbar
