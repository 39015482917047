import * as React from 'react'
import {Text} from '.'
import {StyleSheet, TextProps} from 'react-native'

interface TitleProps extends TextProps {
  style?: object
  children: string
}

function Title({style = {}, children, ...rest}: TitleProps) {
  return (
    <Text style={[styles.text, style]} {...rest}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  text: {
    paddingTop: 2,
  },
})

export default Title
