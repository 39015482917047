import * as React from 'react'
import styled from 'styled-components'
import {View} from 'react-native'
import {NoteTitle} from '../atoms'
import * as variables from '../../variables'
import NoteMenuTabs from './NoteMenuTabs'

export interface NoteInfoProps {
  title: string
  onChange: (value: NoteMenu) => void
}

class NoteInfo extends React.Component<NoteInfoProps> {
  render() {
    return (
      <NoteInfoWrapper>
        <TitleWrapper>
          <NoteTitle title={this.props.title} />
        </TitleWrapper>
        <NoteMenuTabs onChange={this.props.onChange} />
      </NoteInfoWrapper>
    )
  }
}

const NoteInfoWrapper = styled(View)`
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom-color: ${variables.colors.background.greyLighter};
`

const TitleWrapper = styled(View)`
  padding: 10px;
`

export default NoteInfo
