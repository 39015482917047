import * as Actions from './Actions'
import firebase from '../Services/Firebase.web'
import {AsyncStorage} from 'react-native'
import env from '@config/env'
import * as WorkspaceRepository from '../Repositories/WorkspaceRepository'
import * as NoteRepository from '../Repositories/NoteRepository'

export function stateChange(user: ILogin) {
  return (dispatch: any, getState: any) => {
    const {user: sessionUserData}: SessionState = getState().app.Session

    if (
      user === null ||
      sessionUserData === null ||
      user.id !== sessionUserData.id
    ) {
      dispatch({type: Actions.SESSION_STATE_CHANGE, payload: {user}})
    }
  }
}

export function submitLogin(values: LoginValue) {
  return async (dispatch: any) => {
    dispatch({type: Actions.REQUEST_LOGIN, payload: {values}})

    try {
      await firebase.auth().sendSignInLinkToEmail(values.email, {
        url: env.url,
        handleCodeInApp: true,
      })

      await AsyncStorage.setItem('emailForSignIn', values.email)

      window.alert('ログインリンクをメールに送りました！')

      dispatch({type: Actions.FINISH_LOGIN})
    } catch (e) {
      dispatch({type: Actions.FINISH_LOGIN, error: true, payload: e})
    }
  }
}

export function initialize() {
  return async (dispatch: any, getState: any): Promise<InitializeResult> => {
    dispatch({type: Actions.START_INITIALIZE})

    const {user}: SessionState = getState().app.Session

    if (user === null) {
      return {workspace: null, note: null}
    }

    const workspace = await WorkspaceRepository.getInitialWorkspace(user.id)

    if (workspace === null || workspace.id === null) {
      dispatch({type: Actions.REQUEST_WORKSPACE_SETUP})
      return {workspace: null, note: null}
    }

    const note = await NoteRepository.getInitialWorkspace(workspace.id)

    if (note === null) {
      const newNote = await NoteRepository.createFirstNote(workspace.id)

      if (newNote instanceof Error) {
        dispatch({type: Actions.SHOW_ERROR_MESSAGE})
      } else {
        dispatch({
          type: Actions.CHANGE_WORKSPACE,
          payload: {workspace, note: newNote},
        })
      }

      return {workspace, note: null}
    } else {
      dispatch({type: Actions.CHANGE_WORKSPACE, payload: {workspace, note}})
      return {workspace, note}
    }
  }
}

export function requestSignInConfirm() {
  return {type: Actions.REQUEST_LOGIN_CONFIRM}
}

export function finishSignInConfirm() {
  return {type: Actions.FINISH_LOGIN_CONFIRM}
}
