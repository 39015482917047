import * as Actions from './Actions'

export function updateNote(note: INote) {
  return (dispatch: any) => {
    dispatch({type: Actions.UPDATE_NOTE, payload: {note}})
  }
}

export function startListenNote() {
  return {type: Actions.START_LISTEN_NOTE}
}
