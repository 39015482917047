import * as React from 'react'
import loadable from '@loadable/component'
import {connect} from 'react-redux'
import * as NoteService from '../Services/FirebaseNote.web'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import * as NoteModule from '../Modules/NoteModule'
import NoteInfo from './NoteInfoContainer'
import PageContainer from './PageContainer'

const LoadableComponent = loadable(() => import('@comp/pages/NotePage'))

interface NotePageProps {
  note: INote
  loading: boolean
  startListenNote: () => void
  updateNote: (note: INote) => void
}

function NotePage(
  props: NotePageProps & RouteComponentProps<{noteId: string}>,
) {
  const {note, updateNote, match, startListenNote} = props

  React.useEffect(() => {
    const noteId = match.params.noteId
    startListenNote()

    if (noteId) {
      const unsubscribe = NoteService.listenNote(noteId, receiveNote => {
        updateNote(receiveNote)
      })

      return () => {
        unsubscribe()
      }
    }

    return () => {}
  }, [match.params.noteId, startListenNote, updateNote])

  return (
    <LoadableComponent
      NoteInfo={NoteInfo}
      pageCount={note === null ? 0 : note.pageCount}
      pageComponent={PageContainer}
      {...props}
    />
  )
}

const mapStateToProps = ({
  ui: {
    NotePage: {note, loading},
  },
}: AppState) => {
  return {note, loading}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    startListenNote: () => dispatch(NoteModule.startListenNote()),
    updateNote: (note: INote) => dispatch(NoteModule.updateNote(note)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NotePage),
)
