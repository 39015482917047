import * as React from 'react'
import {SideMenu, Note} from '../organisms'
import styled from 'styled-components'
import {View} from 'react-native'

interface NoteTemplateProps {
  pageCount: number
  pageComponent: any
  NoteInfo: any
}

function NoteTemplate({pageCount, NoteInfo, pageComponent}: NoteTemplateProps) {
  return (
    <TemplateWrapper>
      <SideMenu NoteInfo={NoteInfo} />
      <Note pageCount={pageCount} pageComponent={pageComponent} />
    </TemplateWrapper>
  )
}

const TemplateWrapper = styled(View)`
  flex-direction: row;
`

export default NoteTemplate
