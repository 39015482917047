import * as React from 'react'
import loadable from '@loadable/component'
import {connect} from 'react-redux'
import * as SessionModule from '../Modules/SessionModule'

const LoadableComponent = loadable(() => import('@comp/pages/LoginPage'))

function LoginPage(props: any) {
  return <LoadableComponent {...props} />
}

const mapStateToProps = ({
  ui: {
    LoginPage: {submitting, confirming},
  },
}: any) => ({
  submitting,
  confirming,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSubmit: (values: LoginValue) =>
      dispatch(SessionModule.submitLogin(values)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage)
